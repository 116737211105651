<template>
  <div class="download-guide">
    <el-row class="download-guide-anti" type="flex" justify="end">
      <el-col
        class="content"
        :xl="{ span: 9, pull: 3 }"
        :lg="{ span: 9, pull: 3 }"
      >
        <div class="title">反抄袭</div>
        <div class="description">数字文明时代的版权精算师</div>
        <el-row class="down-method" type="flex" justify="space-between">
          <el-col class="qrcode" :xl="{ span: 6 }" :lg="{ span: 6 }">
            <img
              :src="
                antiQrcode
                  ? antiQrcode
                  : require('assets/images/download-guide/app-anti-ios-qrcode.png')
              "
              alt=""
            />
            <span class="text">扫码下载</span>
          </el-col>
          <el-col class="buttons" :xl="{ span: 14 }" :lg="{ span: 13 }">
            <div
              class="edition"
              v-for="(item, index) in antiEdition"
              :key="index"
              @click="handleAntiDown(index)"
            >
              <img :src="item.icon" alt="" />
              <span class="text">{{ item.ver }}版本下载</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="download-guide-idiom" type="flex" justify="start">
      <el-col
        class="content"
        :xl="{ span: 9, push: 3 }"
        :lg="{ span: 9, push: 3 }"
      >
        <div class="title">成语</div>
        <div class="description">数字文明时代的AI小文秘</div>
        <el-row class="down-method" type="flex" justify="space-between">
          <el-col class="buttons" :xl="{ span: 14 }" :lg="{ span: 13 }">
            <div
              class="edition"
              :class="item.ver == 'MacOs' ? 'boxs' : ''"
              v-for="(item, index) in idiomEdition"
              :key="index"
              @click="handleIdiomDown(index)"
            >
              <img :src="item.icon" alt="" />
              <span class="text">{{ item.ver }}版本下载</span>
            </div>
          </el-col>
          <el-col
            v-show="idiomQrcode ? true : false"
            class="qrcode"
            :xl="{ span: 6 }"
            :lg="{ span: 6 }"
          >
            <img :src="idiomQrcode ? idiomQrcode : ''" alt="" />
            <span class="text">扫码下载</span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="download-guide-footer" type="flex" justify="center">
      <el-col :xl="{ span: 15 }" :lg="{ span: 15 }">
        <el-row class="footer-container" type="flex" justify="space-between">
          <el-col class="left" :xl="{ span: 7 }" :lg="{ span: 7 }">
            <div class="logo">
              <img :src="require('assets/images/logo1.png')" alt="" />
            </div>
            <div class="footnav">
              <ul
                class="list"
                v-for="(list, listIndex) in footnav"
                :key="listIndex"
              >
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  @click="handleNav(listIndex, index)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col class="right" :xl="{ span: 5 }" :lg="{ span: 5 }">
            <div class="contact">联系我们</div>
            <div class="contact-number">0755-23345494</div>
            <div class="group">
              <div class="company">
                <img
                  :src="require('../../assets/images/download-guide/01.png')"
                  alt=""
                />
                <span class="text">企业微信</span>
              </div>
              <div class="public">
                <img
                  :src="require('../../assets/images/download-guide/02.png')"
                  alt=""
                />
                <span class="text">公众号</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="copyright">
          <el-col>
            深圳写手智能科技有限公司 Shenzhen writer Intelligent Technology Co.,
            Ltd
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备20062435号</a
            >
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "DownloadGuide",
  data() {
    return {
      footnav: [
        [
          {
            title: "溯源",
            path: "/traceTheSource"
          },
          {
            title: "原创存证",
            path: "/store"
          },
          {
            title: "智能网盘",
            path: "/disk"
          }
        ],
        [
          {
            title: "版权收益",
            path: "/copyrightIncome"
          },
          {
            title: "版权服务",
            path: "/copyrightService"
          },
          {
            title: "关于我们",
            path: "/aboutus"
          }
        ]
      ],
      //反抄袭二维码
      antiEdition: [
        {
          ver: "iOS",
          icon: require("assets/images/download-guide/downguide-ios.png"),
          qrcode: require("assets/images/download-guide/app-anti-ios-qrcode.png")
        },
        {
          ver: "Android",
          icon: require("assets/images/download-guide/downguide-android.png"),
          qrcode: require("assets/images/download-guide/app-anti-android-qrcode1.png")
        }
      ],
      //成语二维码
      idiomEdition: [
        // {
        //   ver : "iOS",
        //   icon : require('assets/images/download-guide/downguide-ios.png'),
        //   qrcode : require('assets/images/introduce/07.png')
        // },
        // {
        //   ver : "Android",
        //   icon : require('assets/images/download-guide/downguide-android.png'),
        //   qrcode : require('assets/images/introduce/07.png')
        // },
        {
          ver: "Windows",
          icon: require("assets/images/download-guide/downguide-windows.png"),
          // qrcode: require("assets/images/introduce/07.png")
          url: "https://www.writemall.com/download/成语.exe"
        },
        {
          ver: "MacOs",
          icon: require("../../assets/images/01/mac.png"),
          url: "https://www.writemall.com/download/成语.dmg"
        }
      ],
      //反抄袭选中版本的二维码
      antiQrcode: "",
      //成语选中版本的二维码
      idiomQrcode: ""
    };
  },
  methods: {
    /**
     * 导航到对应路由
     * @param { Number } i 列表索引
     * @param { Number } j 列表项索引
     */
    handleNav(i, j) {
      this.footnav.forEach((l, li) => {
        if (li === i) {
          l.forEach((t, ti) => {
            if (ti === j) {
              this.$router.push({
                path: t.path
              });
            }
          });
        }
      });
    },
    handleAntiDown(i) {
      this.antiEdition.forEach((a, ai) => {
        if (ai === i) {
          this.antiQrcode = a.qrcode;
        }
      });
    },
    handleIdiomDown(i) {
      this.idiomEdition.forEach((im, imi) => {
        if (imi === i) {
          // this.idiomQrcode = im.qrcode;
          if (im.url) {
            window.location.href = im.url;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  color: #000;
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  div.download-guide {
    &-anti {
      height: 529px;
    }
    &-idiom {
      height: 563px;
    }
    .el-row {
      .content {
        margin-top: 116px;
        .title {
          font-size: 56px;
        }
        .description {
          margin-top: 27px;
          font-size: 24px;
        }
        .down-method {
          margin-top: 63px;
          .qrcode {
            img {
              width: 123px;
              height: 123px;
            }
            .text {
              margin-top: 12px;
              font-size: 17px;
            }
          }
          .buttons {
            .edition {
              width: 248px;
              height: 53px;
              padding-left: 31px;
              line-height: 53px;
              &:first-child ~ .edition {
                margin-top: 16px;
              }
              img {
                width: 25px;
                height: 25px;
                left: 31px;
                top: 50%;
                margin-top: -12.5px;
              }
              .text {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    &-footer {
      height: 232px;
      margin-top: 32px;
      .footer-container {
        .left {
          .logo {
            img {
              width: 129px;
              height: 43px;
            }
          }
          .footnav {
            margin-top: 31px;
            ul {
              li {
                &:first-child ~ li {
                  margin-top: 24px;
                }
                font-size: 12px;
              }
            }
          }
        }
        .right {
          .contact {
            font-size: 16px;
          }
          .contact-number {
            margin-top: 13px;
            font-size: 21px;
          }
          .group {
            margin-top: 36px;
            > div {
              img {
                width: 54px;
                height: 54px;
              }
              .text {
                margin-top: 10px;
                font-size: 12px;
              }
            }
          }
        }
      }

      .copyright {
        height: 49px;
        border-top: 1px solid #edeff5;
        .el-col {
          padding-top: 14px;
        }
        a {
          color: #000;
        }
      }
    }
  }
}
.boxs {
  img {
    margin-top: -15px !important;
  }
}
.download-guide {
  position: absolute;
  top: 63px;
  left: 0;
  width: calc(100vw - 6px);
  .el-row {
    @include noselect;
    .content:nth-child(2) {
      margin-left: 29px;
    }
    .content {
      margin-top: 186px;
      .title {
        font-size: 89px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
      }
      .description {
        margin-top: 44px;
        font-size: 39px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
      }
      .down-method {
        margin-top: 100px;
        .qrcode {
          @include flex-center(column);
          justify-content: normal;
          align-items: center;
          img {
            width: 180px;
            height: 180px;
          }
          .text {
            margin-top: 19px;
            font-size: 28px;
            font-weight: bold;
            color: #000000;
          }
        }
        .buttons {
          margin-left: 2px;
          .edition {
            position: relative;
            width: 396px;
            height: 84px;

            padding-left: 107px;
            text-align: center;
            line-height: 84px;
            background: #4588ff;
            border-radius: 42px;
            box-sizing: border-box;
            cursor: pointer;
            // margin-top: 60px;
            &:first-child ~ .edition {
              margin-top: 26px;
            }
            img {
              position: absolute;
              left: 54px;
              top: 50%;
              margin-top: -20px;
            }
            .text {
              font-size: 29px;
              font-weight: bold;
              color: #fefefe;
            }
          }
        }
      }
    }
  }
  &-anti {
    height: 846px;
    background: url("~assets/images/antiplagiarize-down-bg.png");
    background-size: cover;
  }
  &-idiom {
    height: 900px;
    background: url("~assets/images/idiom-down-bg.png");
    background-size: cover;
  }
  &-footer {
    height: 371px;
    margin-top: 51px;
    > .el-col {
      @include flex-center(column);
      justify-content: stretch;
      align-items: stretch;
    }
    .footer-container {
      flex: 1;
      .left {
        .logo {
          img {
            width: 206px;
            height: 68px;
          }
        }
        .footnav {
          @include flex-between;
          margin-top: 50px;
          ul {
            li {
              &:first-child ~ li {
                margin-top: 39px;
              }
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              cursor: pointer;
            }
          }
        }
      }
      .right {
        .contact {
          text-align: right;
          font-size: 26px;
          font-weight: 800;
          color: #000000;
        }
        .contact-number {
          margin-top: 21px;
          text-align: right;
          font-size: 31px;
          font-weight: 800;
          color: #000000;
        }
        .group {
          @include flex-between;
          margin-top: 57px;
          margin-left: 40px;
          > div {
            @include flex-center(column);
            justify-content: stretch;
            align-items: stretch;
            img {
              width: 86px;
              height: 86px;
            }
            .text {
              margin-top: 17px;
              text-align: center;
              font-size: 14px;
              font-weight: 500;
              color: #000000;
            }
          }
        }
      }
    }
    .copyright {
      height: 79px;
      border-top: 1px solid #edeff5;
      .el-col {
        padding-top: 23px;
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
      }
    }
  }
}
</style>
